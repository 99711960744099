<template>
  <div>
    <PageHeader
      title="Sondages"
      description="Gestion des sondages"
      :button="{
        icon: 'fas fa-plus-circle',
        text: `Ajouter un sondage`,
        action: create
      }"
    />
      <SurveysSubmenu />

    <PageContent>
      <MVPTable
        :gridData="surveys"
        :options="gridOptions"
        @rowDoubleClicked="goToSurvey"
        :showAllDetails="false"
      />
    </PageContent>
  </div>

</template>

<script>
import { http } from '@/services/api.service'
import CreateSurvey from './Modals/Create'
import { mapGetters } from 'vuex'
import { isAfter } from 'date-fns'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import ListRowDetail from './ListRowDetail.vue'
import SurveysSubmenu from './SurveysSubmenu.vue'

export default {
  name: 'app-surveys-list',
  components: {
    PageHeader,
    PageContent,
    ListRowDetail, // eslint-disable-line
    MVPTable,
    SurveysSubmenu
  },
  computed: {
    ...mapGetters([
      'roles',
      'user',
      'domain'
    ]),
    hasEditionRights () {
      return this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))
    },
    isAdmin () {
      return this.roles.includes('admin')
    },
    gridOptions () {
      return {
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        columnDefs: [
          {
            cellRenderer: 'agGroupCellRenderer',
            width: 15,
            suppressMenu: true
          },
          {
            headerName: 'Question',
            field: 'question',
            filter: 'agSetColumnFilter',
            sortable: true
          },
          {
            headerName: 'Date début',
            field: 'start_at',
            type: ['dateColumnFilterable'],
            cellRenderer: this.surveyDatesCellRenderer
          },
          {
            headerName: 'Date fin',
            field: 'end_at',
            type: ['dateColumnFilterable'],
            cellRenderer: this.surveyDatesCellRenderer
          },
          {
            headerName: 'Dupliqué',
            field: 'duplication_of',
            cellRenderer: 'BooleanCellRenderer',
            valueFormatter: params => params.value != null,
            width: 80,
            sortable: true,
            hide: this.domain.slug === 'media-mvp',
            cellStyle: {
              'textAlign': 'center'
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              cellRenderer: 'BooleanFilterCellRenderer',
              buttons: ['reset']
            }
          },
          {
            headerName: 'Duplications',
            type: 'numericColumn',
            field: 'duplications_count',
            filter: 'agNumberColumnFilter',
            width: 80,
            sortable: true,
            hide: this.domain.slug !== 'media-mvp'
          },
          {
            headerName: 'Statut',
            width: 40,
            field: 'is_enabled',
            cellRenderer: 'TagCellRenderer',
            cellRendererParams: {
              tag: {
                labels: {
                  true: 'Activée',
                  false: 'Désactivée'
                }
              }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              cellRenderer: 'TagFilterCellRenderer',
              cellRendererParams: {
                tag: {
                  labels: {
                    true: 'Activée',
                    false: 'Désactivée'
                  }
                },
                buttons: ['reset']
              }
            },
            sortable: true
          },
          {
            headerName: 'Notifié',
            width: 40,
            field: 'is_notified',
            cellRenderer: 'TagCellRenderer',
            cellRendererParams: {
              tag: {
                labels: {
                  true: 'Notifiée',
                  false: 'En attente'
                }
              }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              cellRenderer: 'TagFilterCellRenderer',
              cellRendererParams: {
                tag: {
                  labels: {
                    true: 'Notifiée',
                    false: 'En attente'
                  }
                },
                buttons: ['reset']
              }
            },
            sortable: true
          },
          {
            headerName: 'Stats CSV',
            field: 'uuid',
            cellRenderer: params => {
              if (params.value) {
                return `<a href="#" class="download-stats" data-uuid="${params.value}">Stats</a>`
              } else return ''
            },
            width: 200,
            sortable: false,
            hide: !this.isAdmin
          }
        ]
      }
    }
  },
  watch: {
    domain (value) {
      if (value) this.loadSurveys()
    }
  },
  data () {
    return {
      loading: false,
      surveys: [],
      surveysKeys: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      document.addEventListener('click', this.handleDownloadClick)
    })
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleDownloadClick)
  },
  created () {
    this.loadSurveys()
  },
  methods: {
    goToSurvey ({ data }) {
      if (this.hasEditionRights) {
        this.$router.push({ path: `/app/surveys/${data.uuid}` })
      } else {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Vous n\'avez pas les droits pour éditer un sondage',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'lock'
        })
      }
    },
    loadSurveys () {
      this.loading = true
      http.get('/domain/surveys?past&unique').then(({ data }) => {
        this.surveys = data
        this.surveysKeys = data.map(survey => survey.uuid)
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des sondages',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      }).finally(() => {
        this.loading = false
      })
    },
    isNotLaunchedYet (survey) {
      return isAfter(new Date(survey.start_at), new Date())
    },
    create () {
      this.$buefy.modal.open({
        parent: this,
        component: CreateSurvey,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          created: () => {
            this.loadSurveys()
            this.$buefy.notification.open({
              duration: 4000,
              message: 'Le sondage a été créé avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          }
        }
      })
    },
    surveyDatesCellRenderer (params) {
      if (params.value === null || params.value === undefined) {
        return 'À définir' // Return an empty string if the value is null or undefined
      } else {
        // Format and return the date value as required
        const date = new Date(params.value)
        return new Intl.DateTimeFormat('fr-FR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }).format(date)
      }
    },
    handleDownloadClick (event) {
      const target = event.target
      if (target && target.classList.contains('download-stats')) {
        event.preventDefault()

        const uuid = target.getAttribute('data-uuid')
        if (!uuid) return

        this.downloadStats(uuid)
      }
    },
    async downloadStats (uuid) {
      try {
        const response = await http.get(`/surveys/${uuid}/stats`, { responseType: 'blob' })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `stats_${uuid}.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Erreur lors du téléchargement des statistiques',
          type: 'is-danger'
        })
      }
    }
  }
}
</script>
